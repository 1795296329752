@import './_variables';
@import './_mixins';

* {
  box-sizing: border-box;
  outline: none; }

html,
body {
  height: 100%;
  overflow-x: hidden; }

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-text-color;
  background-color: $main-background-color;
  font-size: $main-text-size; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%; }

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px; }

tr:nth-child(even) {
  background-color: #dddddd; }

@-webkit-keyframes mymove {
  50% {}
  background: rgb(0, 140, 186); }

@keyframes mymove {
  50% {
    border-color: rgb(0, 140, 186);
    background: rgb(0, 140, 186); } }

.blinkClass {
  animation: mymove 2s;
  -webkit-animation: mymove 2s;
  /* Safari and Chrome */ }

.online {
  background: rgb(22,	157, 90); }

.offline {
  background: rgb(252,76,83); }
